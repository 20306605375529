import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import classes from './TableComponent.module.css';

export const GlobalFilter = ({
  filter,
  setFilter,
  placeholder,
  resetHandler
}) => {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 400);
  const clickHandler = () => {
    setValue('');
    resetHandler();
  };
  return (
    <div
      className={`${classes['mt-2']} ${classes['d-flex']} ${classes['flex-row']} ${classes['align-items-center']}`}
    >
      <div>
        <input
          className={`${classes['form-control']} ${classes['p-1']} ${classes['GlobalFilterInput']}`}
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={placeholder}
        />
      </div>
      <div
        onClick={clickHandler}
        className={`${classes['ms-3']} ${classes['GlobalFilterActionBar']}`}
      >
        <span className={`${classes['ResetIcon']}`}></span>
        <label className={`${classes['ms-2']} ${classes['ResetLabel']}`}>
          Reset filter
        </label>
      </div>
    </div>
  );
};
