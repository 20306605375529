import axiosv2 from '../index';

// CEP api resource of icma
const piApiResource = 'pi';
// Axios api for getting the userfleet info from snap
export const pi = {
  serviceStructures: (ppid, deployment) => {
    return axiosv2
      .get(
        `${piApiResource}/${piApiResource}.config/${piApiResource}.servicestructures/${ppid}/${deployment}`
      )
      .then((response) => {
        return response.data.services;
      });
  },
  mapping: (ppid, email) => {
    return axiosv2
      .get(
        `${piApiResource}/${piApiResource}.landing/${piApiResource}.mapping/${ppid}?ppid=${ppid}&email=${email}`
      )
      .then((response) => {
        return response;
      });
  }
};
