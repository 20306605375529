import React, { Component } from 'react';
import HlEmailConfig from '../../IAndCLifecycle/SubAdminPage/HLEmailConfig.js';
import LandingPageComponent from '../../LandingPage/LandingPageComponent.js';

const NewMock = (props) => {
  return (
    <React.Fragment>
      {/* For testing and development purpose */}
      <HlEmailConfig />
      <LandingPageComponent {...props}></LandingPageComponent>
    </React.Fragment>
  );
};

export default NewMock;
