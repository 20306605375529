import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import LoadingScreen from '../../../Ui/LoadingScreen/LoadingScreen';
import classes from './IAndCArchiveMonitor.module.css';
import { pushToError } from '../../../../utils/common';
class IAndCArchiveMonitor extends Component {
  state = {
    htmlApiLoading: true
  };
  componentDidMount() {
    this.props.setCurrentRouteState('aa/monitor', 'I&C Archive');
    if (!this.props.userfleetApiLoading) {
      $('.' + classes.IAndCArchiveMonitor).load(
        '/html/angularContainer.html',
        () => {
          const url = `${this.props.settings.server}aa/aa.static/dashboard-static.html`;
          axios
            .get(url, {
              headers: { Authorization: `Bearer ${this.props.settings.token}` },
            })
            .then(responseData => {
              $('#angularContainer').load(window.makeTextFile(responseData.data));
              this.setState({
                htmlApiLoading: false
              });
            })
            .catch(error => {
              console.error(error);
              pushToError(this.props,this.state.pname,error,url);
            });
        }
      );
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userfleetApiLoading !== this.props.userfleetApiLoading) {
      this.setState({
        htmlApiLoading: true
      });
      $('.' + classes.IAndCArchiveMonitor).load(
        '/html/angularContainer.html',
        () => {
          const url = `${this.props.settings.server}aa/aa.static/dashboard-static.html`;
          axios
            .get(url, {
              headers: { Authorization: `Bearer ${this.props.settings.token}` }
            })
            .then(res => {
              $('#angularContainer').load(window.makeTextFile(res.data));
              this.setState({
                htmlApiLoading: false
              });
            })
            .catch(err => {
              console.error(err);
              pushToError(this.props,this.state.pname,err,url);
            });
        }
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.htmlApiLoading || this.props.userfleetApiLoading ? (
          <LoadingScreen />
        ) : null}
        <div className={classes.IAndCArchiveMonitor}></div>
      </React.Fragment>
    );
  }
}

export default IAndCArchiveMonitor;
