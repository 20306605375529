import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './AppNavList.module.css';

const appNavList = (props) => {
  const availableServices = [
    { aa: 'I&C Archive' },
    { sh: 'I&C Condition' },
    { hl: 'I&C Lifecycle' },
    { tp: 'Process Optimization' },
    { sa: 'I&C Security' },
    { tr: 'Omnivise T3000 Reports' },
    { if: 'Internal Functions' },
    { cp: 'Configuration Page' },
    { ap: 'Admin Page' },
    { um: 'Ui Mock' },
    { ds: 'Data Shopping' }
  ];
  const secondaryNavServices = ['aa', 'sh', 'hl', 'tp', 'sa'];

  let returnElement;
  const serviceNavs = availableServices.map((service) => {
    const serviceId = Object.keys(service)[0];
    let linkHeaderClasses = [classes.LinkHeader];
    let linkClasses = [classes.List];
    if (props.currentService.split('/')[0] === serviceId) {
      linkClasses = [classes.List, classes.ListActive];
      linkHeaderClasses = [classes.LinkHeader, classes.ActiveHeader];
    }
    if (secondaryNavServices.includes(serviceId)) {
      // for contract management
      if (props.isAdmin || props.isSiemens) {
        returnElement = (
          <li
            key={serviceId + '/monitor'}
            className={linkClasses.join(' ')}
            onClick={() => props.activeService(serviceId + '/monitor')}
          >
            <div className={linkHeaderClasses.join(' ')}></div>
            <NavLink to={serviceId + '/monitor'}>{service[serviceId]}</NavLink>
          </li>
        );
      } else if (
        props.contracts.monitors.includes(serviceId) ||
        props.contracts.advisors.includes(serviceId)
      ) {
        switch (serviceId) {
          case 'sh':
            if (!props.shaUnderMaintenance) {
              returnElement = (
                <li
                  key={serviceId + '/monitor'}
                  className={linkClasses.join(' ')}
                  onClick={() => props.activeService(serviceId + '/monitor')}
                >
                  <div className={linkHeaderClasses.join(' ')}></div>
                  <NavLink to={serviceId + '/monitor'}>
                    {service[serviceId]}
                  </NavLink>
                </li>
              );
            } else {
              returnElement = null;
            }
            break;
          case 'hl':
            if (!props.hlUnderMaintenance) {
              returnElement = (
                <li
                  key={serviceId + '/monitor'}
                  className={linkClasses.join(' ')}
                  onClick={() => props.activeService(serviceId + '/monitor')}
                >
                  <div className={linkHeaderClasses.join(' ')}></div>
                  <NavLink to={serviceId + '/monitor'}>
                    {service[serviceId]}
                  </NavLink>
                </li>
              );
            } else {
              returnElement = null;
            }
            break;
          case 'sa':
            if (!props.saUnderMaintenance) {
              returnElement = (
                <li
                  key={serviceId + '/monitor'}
                  className={linkClasses.join(' ')}
                  onClick={() => props.activeService(serviceId + '/monitor')}
                >
                  <div className={linkHeaderClasses.join(' ')}></div>
                  <NavLink to={serviceId + '/monitor'}>
                    {service[serviceId]}
                  </NavLink>
                </li>
              );
            } else {
              returnElement = null;
            }
            break;
          default:
            returnElement = (
              <li
                key={serviceId + '/monitor'}
                className={linkClasses.join(' ')}
                onClick={() => props.activeService(serviceId + '/monitor')}
              >
                <div className={linkHeaderClasses.join(' ')}></div>
                <NavLink to={serviceId + '/monitor'}>
                  {service[serviceId]}
                </NavLink>
              </li>
            );
        }
      } else {
        returnElement = null;
      }
    } else {
      returnElement = (
        <li
          key={serviceId}
          className={linkClasses.join(' ')}
          onClick={() => props.activeService(serviceId)}
        >
          <div className={linkHeaderClasses.join(' ')}></div>
          <NavLink to={serviceId}>{service[serviceId]}</NavLink>
        </li>
      );
    }

    // if should be visible only for isSiemens user
    if (serviceId === 'if') {
      if (!props.isSiemens) {
        returnElement = null;
      }
    }
    // um should be visible only for isSiemens user and for dev env
    if (serviceId === 'um') {
      if (!props.isSiemens) {
        returnElement = null;
      } else if (
        !(
          window.location.hostname ===
            'native-app.icma-dev.siemens-energy.cloud' ||
          window.location.hostname === 'localhost'
        )
      ) {
        returnElement = null;
      }
    }

    //ap should be visible only for isAdmin or isSiemens user
    if (serviceId === 'ap' || serviceId === 'ds') {
      if (!props.isAdmin && !props.isSiemens) {
        returnElement = null;
      }
    }

    // t3000reports should be visible only if ppid is included in t3000ppid list
    if (serviceId === 'tr') {
      if (props.isSiemens) {
        returnElement = (
          <li
            key={serviceId}
            className={linkClasses.join(' ')}
            onClick={() => props.activeService(serviceId)}
          >
            <div className={linkHeaderClasses.join(' ')}></div>
            <NavLink to={serviceId}>{service[serviceId]}</NavLink>
          </li>
        );
      } else {
        if (props.contracts.advisors.includes('tr')) {
          returnElement = (
            <li
              key={serviceId}
              className={linkClasses.join(' ')}
              onClick={() => props.activeService(serviceId)}
            >
              <div className={linkHeaderClasses.join(' ')}></div>
              <NavLink to={serviceId}>{service[serviceId]}</NavLink>
            </li>
          );
        } else {
          returnElement = null;
        }
      }
    }

    if (serviceId === 'sh' || serviceId === 'sa') {
      if (returnElement === null) {
        if (
          props.contracts.monitors.includes('tpi') ||
          props.contracts.advisors.includes('tpi')
        ) {
          returnElement = (
            <li
              key={serviceId + '/monitor'}
              className={linkClasses.join(' ')}
              onClick={() => props.activeService(serviceId + '/monitor')}
            >
              <div className={linkHeaderClasses.join(' ')}></div>
              <NavLink to={serviceId + '/monitor'}>
                {service[serviceId]}
              </NavLink>
            </li>
          );
        }
      }
    }
    return returnElement;
  });

  return (
    <div className={classes.AppNavList}>
      <ul>
        <li className={classes.List} onClick={() => props.activeService('')}>
          <div
            style={{
              height: '49px',
              width: '15px',
              float: 'left',
              marginLeft: '-15px',
              marginRight: '34px',
              marginTop: '-15px'
            }}
          ></div>
          <NavLink to="/">I&C Monitors and Advisors</NavLink>
        </li>
        {serviceNavs}
      </ul>
    </div>
  );
};

export default appNavList;
