export const MENU_SHORT_DESCRIPTION = [
    // under General Settings Mock
  {
    "url": "pimd",
    "menu name": "Master Data",
    "description": "Define the general parameters of your Power Plant ",
  },
  // Under Archive Analytics
  {
    "url": "aags",
    "menu name": "General Settings",
    "description": "Define the global limits used in I&C Archive Monitor",
  },
  {
    "url": "aaalert",
    "menu name": "Alert Load Reduction",
    "description": "Define the limits used in I&C Archive Monitor for Alert Load",
  },
  {
    "url": "aaoper",
    "menu name": "Manual Operation Load Reduction",
    "description":"Define the limits used in I&C Archive Monitor for Manual Operation Load",
  },
  {
    "url": "aaana",
    "menu name": "Analog Archive Optimization",
    "description":"Define the limits used in I&C Archive Monitor for Analog Archive Optimization",
  },
  {
    "url": "aabin",
    "menu name": "Binary Archive Optimization",
    "description":"Define the limits used in I&C Archive Monitor for Binary Archive Optimization",
  },
  {
    "url": "aaup",
    "menu name": "General Settings Start Stop",
    "description": "Define the parameters for the turbine startup and shutdown procedure",
  },
  // Under Hardware Lifecycle
  {
    "url": "pcemail",
    "menu name": "Email Configuration",
    "description":"Define the email ID(s) of people to be informed about changes in the product lifecycle of components",
  },
  //under Process Optimization
  {
    "url": "tpglo",
    "menu name": "Global parameters",
    "description":"This page is used for Global parameters.",
  },
  {
    "url": "tpem",
    "menu name": "Emissions and Efficiency",
    "description": "This page is used for Emissions and Efficiency.",
  },
  {
    "url": "tpsbo",
    "menu name": "Sootblower Optimizer Report",
    "description":"This page is used for Sootblower Optimizer Report.",
  },
  {
    "url": "tplm",
    "menu name": "Laser Measurement Report",
    "description":"This page is used for Laser Measurement Report.",
  },
  {
    "url": "tpsopi",
    "menu name": "Sootblower Optimizer Potential Identifier",
    "description":"This page is used for Sootblower Optimizer Potential Identifier.",
  },
  {
    "url": "tpumi",
    "menu name": "Unit Controler Model Identifier",
    "description":"This page is used for Unit Controler Model Identifier.",
  }
  ];
  