export const MENU_SHORT_DESCRIPTION = [
  {
    name: "scdp/sh.static/html/sh_uploadZip.html",
    description: "Manually upload power plant data."
  },
  {
    name: "scdp/sh.static/html/sh_downloadZip.html",
    description: "Download diagnostic and configuration data (HSS) for a site.",
  },
  {
    name: "pc/pc.static/html/pc_casbGeneration.html",
    description: "Generate the (as-built) component list (CASB) for the current unit.",
  },
  {
    name: "sha/sh.static/html/sh_enableMaintenance.html",
    description: "Enable maintenance mode for the current unit.",
  },
  {
    name: "sha/sh.static/html/sh_disableMaintenance.html",
    description: "Disable maintenance mode for the current unit.",
  },
  {
    name: "hl/hl.static/html/hl_webBfs.html",
    description: "Manage and compare WebBFS data.",
  },
  {
    name: "hl/hl.static/html/hl_emailConfig.html",
    description: "Configure email alerts in case hardware are deleted from the Components list.",
  },
  {
    name: "scdp/sh.static/html/sh_checkprofile.html",
    description: "Configure test profiles for the hardware components.",
  },
  {
    name: "scdp/sh.static/html/sh_checkconfig.html",
    description: "Check and modify the test profile descriptions.",
  },
  {
    name: "scdp/sh.static/html/sh_emailConfig.html",
    description: "Email configuration for component data (currently not in use).",
  },
  {
    name: "scdp/sh.static/html/sh_sctrigger.html",
    description: "Manually trigger the System Check analytic.",
  },
  {
    name: "scdp/sh.static/html/sh_riReport.html",
    description: "Download RI 2.0 Reports",
  },
  {
    name: "scdp/sh.static/html/sh_alarmOverview.html",
    description: "Show overview of all active alarms from SystemCheck.",
  },
  {
    name: "scdp/sh.static/html/sh_deactivatedOverview.html",
    description: "Overview of deactivated alarms from all sites.",
  },
  {
    name: "scdp/sh.static/html/sh_clientDetails.html",
    description: "View and configure Customer/Unit contract management for SystemCheck.",
  },
  {
    name: "scdp/sh.static/html/sh_t3000ProxySearch.html",
    description: "Search for T3000 Proxies.",
  },
  {
    name: "scdp/sh.static/html/sh_reportImprint.html",
    description: "View and change the imprint of TPI reports.",
  },
  {
    name: "sha/sh.static/html/sh_tpiemailConfig.html",
    description: "Configure emails for TPI feedback form.",
  },
  {
    name: "sha/sh.static/html/sh_cnrelease.html",
    description: "Release new change notices which were uploaded from PICS.",
  },
  {
    name: "sha/sh.static/html/sh_cnlock.html",
    description: "Lock change notices.",
  },
  {
    name: "sha/sh.static/html/sh_tpireport.html",
    description: "Generate TPI test reports incluing all change notices (released or locked).",
  },
  {
    name: "sha/sh.static/html/sh_tpicleanup.html",
    description: "Delete old and unnecessary TPI data.",
  },
  {
    name: "sha/sh.static/html/sh_tpiTrigger.html",
    description: "Manually trigger calculation of TPI analytics.",
  },
  {
    name: "sha/sh.static/html/sh_uisDownload.html",
    description: "Download UIS 2.0 Reports",
  },
  {
    name: "sha/sh.static/html/sh_deltafile.html",
    description: "Show a list of deltas of the plant configuration based on matrix.csv and SIMIT files.",
  },
  {
    name: "sha/sh.static/html/sh_tpiresult.html",
    description: "Download latest plant configuration: matrix.csv and SIMIT files.",
  },
  {
    name: "sha/sh.static/html/sh_tpiBackupRestoreTrigger.html",
    description: "Backup or restore TPI data (change notices and unit data).",
  },
  {
    name: "scdp/sh.static/html/sh_secCheckconfig.html",
    description: "View and config properties for security checks.",
  },
  {
    name: "sa/sa.static/html/sa_secprofile.html",
    description: "Manually trigger creation of the security profile.",
  },
  {
    name: "scdp/sh.static/html/sh_secAlarmOverview.html",
    description: "Show and act on all reported security alarms.",
  },
  {
    name: "scdp/sh.static/html/sh_secDeactivatedOverview.html",
    description: "Show deactivated security alarms.",
  },
  {
    name: "scdp/sh.static/html/sh_alarmDashBoard.html",
    description: "Show Cyber Inspection alarm overview for all units.",
  },
  {
    name: "scdp/sh.static/html/sh_cyberInspection.html",
    description: "Show Cyber Inspection overview for tickets and checks for all units.",
  },
  {
    name: "scdp/sh.static/html/sh_cyberInspectionTrigger.html",
    description: "Manually trigger calculation of Cyber Security analytics.",
  },
  {
    name: "sa/sa.static/html/sa_dataIngestionErrorLogs.html",
    description: "View logs from the Snowflake data ingestion Lambda.",
  },
  {
    name: "scdp/sh.static/html/sh_uploadGenericQuery.html",
    description: "Upload the GenericQuery.xml file.",
  },
  {
    name: "scdp/sh.static/html/sh_displayGenericQuery.html",
    description:"Display and export the results of generic queries from GenericQuery.xml.",
  },
  {
    name: "scdp/sh.static/html/sh_secAlarmOverviewV5.html",
    description: "View and edit cyber inspection alarms.",
  },
  {
    name: "scdp/sh.static/html/sh_aatrigger.html",
    description: "Manually trigger the I&C Archive Startup / Shutdown analytics.",
  },
  {
    name: "scdp/sh.static/html/sh_aaConfig.html",
    description: "Export/Import the I&C Archive Startup / Shutdown configuration.",
  },
  {
    name: "scdp/sh.static/html/sh_configTurbines.html",
    description: "View the I&C Archive Startup / Shutdown configuration of all turbines.",
  },
  {
    name: "tp/tp.static/html/tp_emailConfig.html",
    description: "Configure the service request recipients' email.",
  }
  ];
  