import React, { Component } from 'react';
import Backdrop from '../Ui/Backdrop/Backdrop';
import CloseIcon from '../../assets/images/x.svg';
import TableComponent from '../Ui/TableComponent/TableComponent';
import { GlobalFilter } from '../Ui/TableComponent/GlobalFilter';
import classes from './PlantSelector.module.css';

class PlantSelector extends Component {
  state = {
    isLoading: false,
    globalFilter: ''
  };
  handleSelectedRows = (selectedRows) => {
    // Perform actions with selectedRow if required
  };
  onRowSelectHandler = (selectedRow) => {
    if (selectedRow?.unitId) {
      this.props.changePpid(selectedRow?.unitId);
    } else {
      this.props.changePpid(selectedRow?.ppid);
    }
  };
  setGlobalFilter = (val) => {
    this.setState({
      globalFilter: val
    });
  };
  filterResetHandler = () => {
    this.setGlobalFilter('');
  };

  cellFormatter = ({ value, row }) => {
    let customClass;
    // check if the row ppid or unit is same as current props ppid and row aname is same as current props aname
    if (
      this.props.aname === row.original.aname &&
      (this.props.ppid === row.original.ppid ||
        this.props.ppid === row.original.unitId)
    ) {
      customClass = classes.CustomHighlight;
    } else {
      customClass = classes.DefaultCell;
    }
    return <span className={`${customClass}`}>{value}</span>;
  };

  render() {
    const tableOptions = [
      {
        Header: 'Account name',
        accessor: 'aname',
        className: `${classes['ps-3']} ${classes['pe-0']}`,
        headerClassName: `${classes['ps-3']} ${classes['pe-0']}`,
        i18n: false,
        Cell: this.cellFormatter
      },
      {
        Header: 'Plant name',
        accessor: 'pname',
        className: `${classes['ps-3']} ${classes['pe-0']}`,
        headerClassName: `${classes['ps-3']} ${classes['pe-0']}`,
        i18n: false,
        Cell: this.cellFormatter
      },
      {
        Header: 'Unit name',
        accessor: 'uname',
        className: `${classes['ps-3']} ${classes['pe-0']}`,
        headerClassName: `${classes['ps-3']} ${classes['pe-0']}`,
        i18n: false,
        Cell: this.cellFormatter
      }
    ];
    return (
      <React.Fragment>
        <div className={classes.PlantSelector}>
          <div
            className={`${classes['d-flex']} ${classes['justify-content-end']} ${classes['align-items-center']} ${classes['mb-2']}`}
          >
            <img
              src={CloseIcon}
              alt=""
              onClick={this.props.close}
              className={classes.CloseIcon}
            />
          </div>
          <div
            className={`${classes['d-flex']} ${classes['justify-content-between']} ${classes['align-items-center']}`}
          >
            <h1>Plant selector</h1>
            <GlobalFilter
              filter={this.state.globalFilter}
              setFilter={this.setGlobalFilter}
              placeholder="Search..."
              resetHandler={this.filterResetHandler}
            />
          </div>
          <TableComponent
            data={this.props.equipments}
            sortable={true}
            enableColumnFilter={false}
            enableSelection={false}
            enableSelectAll={false}
            globalFilter={this.state.globalFilter}
            onRowSelect={this.onRowSelectHandler}
            columns={tableOptions}
            isLoading={this.state.isLoading}
            onselectedRows={this.handleSelectedRows}
          />
        </div>
        <Backdrop show={true} clicked={this.props.close} />
        {this.state.customSearchDropdownVisible && (
          <Backdrop
            show={true}
            customSelect="parent"
            clicked={this.onDropdownClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default PlantSelector;
