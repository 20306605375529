import React from 'react';

import Wait from '../../../assets/images/wait.gif';
import Backdrop from '../Backdrop/Backdrop';
import classes from './LoadingScreen.module.css';

const loadingScreen = (props) => {
  return (
    <React.Fragment>
      <div className={classes.LoadingScreen}>
        <img src={Wait} alt="" />
      </div>
      <Backdrop show={true} />
    </React.Fragment>
  );
};

export default loadingScreen;
