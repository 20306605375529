import React from 'react';

import SiemensEnergyLogo from '../../../assets/images/SE_Logo_White_RGB.png';
import HamburgerIcon from '../../../assets/images/menuIcon.svg';
import RightArrow from '../../../assets/images/caret-right-fill.svg';
import { withRouter } from 'react-router-dom';
import classes from './PrimaryNavbar.module.css';
import Select from 'react-select';
import DiffernetFleetCase from '../../../config/different_fleet_case.json';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: `${state.isDisabled ? '#2b1e61' : ''}`,
    cursor: 'pointer'
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected
        ? '#2b1e61'
        : state.isFocused
        ? '#5f5781'
        : '#ffffff',
      color: state.isSelected || state.isFocused ? '#ffffff' : '#212529',
      cursor: 'pointer'
    };
  },
  control: (provided, state) => ({
    display: 'flex',
    padding: '5px',
    borderRadius: '0.25rem',
    border: '1px solid #ffffff',
    background: '#2b1e61'
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#f5f5f5'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0
  }),
  input: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: '#ffffff'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#ffffff'
  })
};

const primaryNavbar = (props) => {
  const handleAppNameClick = () => {
    props.history.push('/');
  };

  const openPlantSelector = () => {
    props.openPlantSelection();
  };
  const createSelectOptions = (label, value = label) => ({
    label,
    value
  });

  const aNameElement =
    props?.aname?.length > 25 ? (
      <div title={props.aname}>{props.aname.slice(0, 25).concat('...')}</div>
    ) : (
      <div>{props.aname}</div>
    );

  let isUnitPpid = props?.ppid?.indexOf('u') > 0;

  const slicedPpid = props?.ppid?.slice(
    0,
    isUnitPpid ? props?.ppid?.indexOf('u') : props.ppid
  );
  const currentUnitName = props?.ppidToPnameMapping?.[props?.ppid];
  let unitOptions = isUnitPpid
    ? Array.from(props?.ppidToUnitsMapping?.[slicedPpid] || []).map((unitId) =>
        createSelectOptions(props?.ppidToPnameMapping[unitId], unitId)
      )
    : [];

  // ppidToPnameMapping- keys  - loop on keys if ppid contain key:
  // let keysPpidToPnameMapping = Object.keys(props?.ppidToPnameMapping);
  // let slicedNonAlphaPpid = removeAfterAlphabet(props?.ppid);

  // for case like Niederaussem (where unitID doen't contain "u") - 5000 - 5000u1 -
  // function findRelatedStrings(prefix, largeArray) {
  //   // Use the base string to create a regular expression
  //   const regex = new RegExp(`^${prefix}`);
  //   // Filter the large array to find strings that match the regular expression
  //   // and are not equal to the base string
  //   const relatedStrings = largeArray.filter(
  //     (str) => regex.test(str) && str !== prefix
  //   );
  //   return relatedStrings;
  // }

  // for caselike DEMO id's
  // issue for wolfburg & fix for Niederaussem
  // if (slicedNonAlphaPpid) {
  //   let relatedPpid = findRelatedStrings(
  //     slicedNonAlphaPpid,
  //     keysPpidToPnameMapping
  //   );
  //   console.log('relatedPpid', relatedPpid);
  //   if (relatedPpid.length > 0) {
  //     isUnitPpid = true;
  //     unitOptions = relatedPpid.map((unitId) =>
  //       createSelectOptions(props?.ppidToPnameMapping[unitId], unitId)
  //     );
  //   }
  // } else {

  // }
  let parentIDHasUnit = Array.from(
    props?.ppidToUnitsMapping?.[props?.parentID] || []
  );
  // to handle case where child plants can have ppid or ibid
  if (parentIDHasUnit) isUnitPpid = true;
  unitOptions = parentIDHasUnit.map((unitId) =>
    createSelectOptions(props?.ppidToPnameMapping?.[unitId], unitId)
  );
  // single plant case - Gabriel
  if (parentIDHasUnit.length === 0) {
    let singlePlantOptions = createSelectOptions(
      props?.ppidToPnameMapping?.[props?.ppid],
      props?.ppid
    );
    unitOptions = [singlePlantOptions];
  }

  // # patch for Charlottenburg like plant
  if (
    !isUnitPpid &&
    props.ppid &&
    DiffernetFleetCase.hasOwnProperty(props.ppid)
  ) {
    unitOptions = Array.from(props?.ppidToUnitsMapping?.[props.ppid]).map(
      (unitId) => {
        return createSelectOptions(props?.ppidToPnameMapping[unitId], unitId);
      }
    );
    isUnitPpid = true;
  }
  const defaultValue = (options, value) => {
    return options
      ? options.find((option) => option.label === value)
        ? options.find((option) => option.label === value)
        : null
      : null;
  };

  const unitOnChange = ({ value }) => {
    props.changePpid(value);
  };

  return (
    <div className={classes.UpperNavContainer}>
      <div className={classes.UpperNav}>
        <img
          src={SiemensEnergyLogo}
          className={classes.SiemensEnergyLogo}
          alt=""
        />
        <div className={classes.NavbarContainer}>
          <div onClick={handleAppNameClick} className={classes.AppName}>
            I&C Monitors and Advisors
          </div>
          <div className={classes.FacilityContainer}>
            <div className={classes.FacilityDiv}>
              {props?.aname && (
                <div
                  className={classes.FacilityName}
                  onClick={openPlantSelector}
                >
                  {aNameElement}
                </div>
              )}
              {props?.aname && props?.pname && (
                <div className={classes.Seperator}>
                  <img src={RightArrow} alt="right arrow" />{' '}
                </div>
              )}
              {props?.pname && (
                <div
                  id="plantname"
                  className={`${!isUnitPpid && classes.PlantName}`}
                >
                  {!isUnitPpid ? (
                    <div>{props.pname}</div>
                  ) : (
                    <Select
                      options={unitOptions}
                      styles={customStyles}
                      value={defaultValue(unitOptions, currentUnitName)}
                      onChange={unitOnChange}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.ToggleDiv}>
          <div className={classes.DrawerToggle} onClick={props.toggle}>
            <img src={HamburgerIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(primaryNavbar);
