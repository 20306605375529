import React from 'react';

import classes from './SecondaryNavbar.module.css';

import Layer1 from './Layer1/Layer1';
import Layer2 from './Layer2/Layer2';
import Layer2Standalone from './Layer2Standalone/Layer2Standalone';

const secondaryNavbar = (props) => {
  return (
    <div className={classes.SecondaryNavbar}>
      <Layer1
        isAdmin={props.isAdmin}
        isSiemens={props.isSiemens}
        activeService={props.activeService}
        currentServiceName={props.currentServiceName}
        currentService={props.currentService}
        openTermsAndConditions={props.openTermsAndConditions}
      />
      {props.currentService === '' ? null : (
        <React.Fragment>
          {['ap', 'cp', 'if', 'tr', 'um', 'ds'].includes(
            props.currentService
          ) ? (
            <Layer2Standalone
              isAdmin={props.isAdmin}
              isSiemens={props.isSiemens}
              currentService={props.currentService}
              refreshMock={props.refreshMock}
              activeService={props.activeService}
            />
          ) : (
            <Layer2
              isAdmin={props.isAdmin}
              isSiemens={props.isSiemens}
              currentService={props.currentService}
              activeService={props.activeService}
              contracts={props.contracts}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default secondaryNavbar;
