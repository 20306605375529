import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import LoadingScreen from '../../../Ui/LoadingScreen/LoadingScreen';

import classes from './IAndCSecurityMonitor.module.css';
import { pushToError } from '../../../../utils/common';
class IAndCSecurityMonitor extends Component {
  state = {
    htmlApiLoading: true,
  };
  componentDidMount() {
    this.props.setCurrentRouteState('sa/monitor', 'I&C Security');
    if (!this.props.userfleetApiLoading) {
      $('.' + classes.IAndCSecurityMonitor).load(
        '/html/angularContainer.html',
        () => {
          const url = `${this.props.settings.server}sa/sa.static/html/sa_dashboard.html`;
          axios
            .get(url, {
              headers: { Authorization: `Bearer ${this.props.settings.token}` },
            })
            .then((response) => {
              $('#angularContainer').load(window.makeTextFile(response.data));
              this.setState({
                htmlApiLoading: false,
              });
            })
            .catch((err) => {
              console.error(err);
              pushToError(this.props,this.state.pname,err,url);
            });
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userfleetApiLoading !== this.props.userfleetApiLoading) {
      this.setState({
        htmlApiLoading: true,
      });
      $('.' + classes.IAndCSecurityMonitor).load(
        '/html/angularContainer.html',
        () => {
          const url = `${this.props.settings.server}sa/sa.static/html/sa_dashboard.html`;
          axios
            .get(url, {
              headers: { Authorization: `Bearer ${this.props.settings.token}` },
            })
            .then((response) => {
              $('#angularContainer').load(window.makeTextFile(response.data));
              this.setState({
                htmlApiLoading: false,
              });
            })
            .catch((err) => {
              console.error(err);
              pushToError(this.props,this.state.pname,err,url);
            });
        }
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.htmlApiLoading || this.props.userfleetApiLoading ? (
          <LoadingScreen />
        ) : null}
        <div className={classes.IAndCSecurityMonitor}></div>
      </React.Fragment>
    );
  }
}

export default IAndCSecurityMonitor;
