import { createSlice } from '@reduxjs/toolkit';

export const userFleetSlice = createSlice({
  name: 'userfleet',
  initialState: {
    userfleet: {}
  },
  reducers: {
    updateFleet: (state, value) => {
      state.userfleet = value;
    }
  }
});

export const { updateFleet } = userFleetSlice.actions;

export default userFleetSlice.reducer;
