import React from "react";
import PropTypes from "prop-types";
 
const Button = ({ children, className, loading, loadingText, ...props }) => {
  let button = (
    <button {...props} className={className}>
      {children}
    </button>
  );
  if (loading) {
    button = (
      <button className={className} type="button" disabled>
        {loadingText}
        <span
          className="ms-2 spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    );
  }
  return button;
};
Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};
export default Button;