export const initializeCiv4 = (props) => {
  let paramsCic4 = window.location.href.split('?')[1];
  let path = window.location.pathname;

  let isShowCiv4 =
    (paramsCic4 && paramsCic4.indexOf('civ4') !== -1) ||
    path === '/sa/advisor_civ4'
      ? true
      : false;
  isShowCiv4 && sessionStorage.setItem('isShowCiv4', isShowCiv4);
  if (isShowCiv4 && path !== '/sa/advisor_civ4') {
    window.location.href = '/sa/advisor_civ4';
  }
};
