import React, { useState } from 'react';
import classes from './Toggle.module.css';
const ToggleSwitch = (props) => {
  const [isChecked, setIsChecked] = useState(props.checked);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    props.updateValue(!isChecked);
  };

  return (
    <div className={classes.container}>
      {props.children}{' '}
      <label className={classes.switch}>
        <input type={'checkbox'} checked={isChecked} onChange={handleToggle} />
        <span className={classes.slider} />
      </label>
    </div>
  );
};

export default ToggleSwitch;
