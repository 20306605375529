import React, { useState } from 'react';

import classes from './Notification.module.css';
import CloseButton from '../../../assets/images/cross.svg';

const Notification = (props) => {
  const [closeNotification, setCloseNotification] = useState(true);

  if (closeNotification) {
    return (
      <div
        className={`${classes.Notification}  ${
          props.severity === 'info' ? classes.Info : classes.Warn
        }`}
      >
        <div className={classes.BannerText}>{props.bannertext}</div>
        <i
          className={`bi bi-x ${classes.CloseButton}`}
          style={{fontSize:"25px"}}
          // className={classes.CloseButton}
          alt="close button"
          src={CloseButton}
          onClick={() => setCloseNotification(false)}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default Notification;
