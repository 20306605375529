import React from 'react';

import ReactTooltip from 'react-tooltip';
import MonitorOpen from '../../../../assets/images/monitor-open.svg';
import MonitorClose from '../../../../assets/images/monitor-close.svg';
import AdvisorOpen from '../../../../assets/images/advisors-open.svg';
import AdvisorClose from '../../../../assets/images/Advisors-close.svg';
import ComponentOpen from '../../../../assets/images/component-open.svg';
import ComponentClose from '../../../../assets/images/component-close.svg';
/* import ServicesOpen from "../../../../assets/images/services-open.svg";
import ServicesClose from "../../../../assets/images/services-close.svg"; */
import ConfigIcon from '../../../../assets/images/config.svg';
import AdminIcon from '../../../../assets/images/Admin.png';
import classes from './Layer2.module.css';
import { getItemFromSessionStorage } from '../../../../utils/common';
const layer2 = (props) => {
  const selectedService = props.currentService.split('/')[0];
  const selectedUsecase = props.currentService.split('/')[1];
  const serviceListWithoutComponents = ['aa', 'sa', 'tp', 'sh'];
  let isShowCiv4Local = getItemFromSessionStorage('isShowCiv4');
  return (
    <div className={classes.Layer2}>
      <div className={classes.PagesContainer}>
        {props.isSiemens ||
        props.isAdmin ||
        props.contracts.monitors.includes(selectedService) ||
        props.contracts.advisors.includes(selectedService) ? (
          <React.Fragment>
            {selectedUsecase === 'monitor' ? (
              <div
                className={classes.PageModule}
                onClick={() =>
                  props.activeService(selectedService + '/monitor')
                }
              >
                <img
                  alt=""
                  src={MonitorOpen}
                  className={classes.PageModuleImage}
                />
                <div className={classes.PageModuleFontActive}>MONITOR</div>
              </div>
            ) : (
              <div
                className={classes.PageModule}
                onClick={() =>
                  props.activeService(selectedService + '/monitor')
                }
              >
                <img
                  alt=""
                  src={MonitorClose}
                  className={classes.PageModuleImage}
                />
                <div className={classes.PageModuleFont}>MONITOR</div>
              </div>
            )}
            <div className={classes.PageModuleSeperator}></div>
          </React.Fragment>
        ) : selectedService === 'sh' || selectedService === 'sa' ? (
          props.contracts.monitors.includes('tpi') ||
          props.contracts.advisors.includes('tpi') ? (
            <React.Fragment>
              {selectedUsecase === 'monitor' ? (
                <div
                  className={classes.PageModule}
                  onClick={() =>
                    props.activeService(selectedService + '/monitor')
                  }
                >
                  <img
                    alt=""
                    src={MonitorOpen}
                    className={classes.PageModuleImage}
                  />
                  <div className={classes.PageModuleFontActive}>MONITOR</div>
                </div>
              ) : (
                <div
                  className={classes.PageModule}
                  onClick={() =>
                    props.activeService(selectedService + '/monitor')
                  }
                >
                  <img
                    alt=""
                    src={MonitorClose}
                    className={classes.PageModuleImage}
                  />
                  <div className={classes.PageModuleFont}>MONITOR</div>
                </div>
              )}
              <div className={classes.PageModuleSeperator}></div>
            </React.Fragment>
          ) : null
        ) : null}

        {props.isSiemens ||
        props.isAdmin ||
        props.contracts.advisors.includes(selectedService) ? (
          <React.Fragment>
            {selectedUsecase === 'advisor' ? (
              <div
                className={classes.PageModule}
                onClick={() =>
                  props.activeService(selectedService + '/advisor')
                }
              >
                <img
                  alt=""
                  src={AdvisorOpen}
                  className={classes.PageModuleImage}
                />
                <div className={classes.PageModuleFontActive}>ADVISORS</div>
              </div>
            ) : (
              <div
                className={classes.PageModule}
                onClick={() =>
                  props.activeService(selectedService + '/advisor')
                }
              >
                <img
                  alt=""
                  src={AdvisorClose}
                  className={classes.PageModuleImage}
                />
                <div className={classes.PageModuleFont}>ADVISORS</div>
              </div>
            )}
            {selectedService === 'sa' && props.isSiemens && isShowCiv4Local ? (
              <React.Fragment>
                <div className={classes.PageModuleSeperator}></div>
                {selectedUsecase === 'advisor_civ4' ? (
                  <div
                    className={classes.PageModule}
                    onClick={() =>
                      props.activeService(selectedService + '/advisor_civ4')
                    }
                  >
                    <img
                      alt=""
                      src={AdvisorOpen}
                      className={classes.PageModuleImage}
                    />
                    <div className={classes.PageModuleFontActive}>
                      ADVISORS (CIv4)
                    </div>
                  </div>
                ) : (
                  <div
                    className={classes.PageModule}
                    onClick={() =>
                      props.activeService(selectedService + '/advisor_civ4')
                    }
                  >
                    <img
                      alt=""
                      src={AdvisorClose}
                      className={classes.PageModuleImage}
                    />
                    <div className={classes.PageModuleFont}>
                      ADVISORS (CIv4)
                    </div>
                  </div>
                )}
              </React.Fragment>
            ) : null}
            <div className={classes.PageModuleSeperator}></div>
          </React.Fragment>
        ) : selectedService === 'sh' || selectedService === 'sa' ? (
          props.contracts.advisors.includes('tpi') ? (
            <React.Fragment>
              {selectedUsecase === 'advisor' ? (
                <div
                  className={classes.PageModule}
                  onClick={() =>
                    props.activeService(selectedService + '/advisor')
                  }
                >
                  <img
                    alt=""
                    src={AdvisorOpen}
                    className={classes.PageModuleImage}
                  />
                  <div className={classes.PageModuleFontActive}>ADVISORS</div>
                </div>
              ) : (
                <div
                  className={classes.PageModule}
                  onClick={() =>
                    props.activeService(selectedService + '/advisor')
                  }
                >
                  <img
                    alt=""
                    src={AdvisorClose}
                    className={classes.PageModuleImage}
                  />
                  <div className={classes.PageModuleFont}>ADVISORS</div>
                </div>
              )}
              <div className={classes.PageModuleSeperator}></div>
            </React.Fragment>
          ) : null
        ) : null}

        {!serviceListWithoutComponents.includes(selectedService) ? (
          <React.Fragment>
            {' '}
            {selectedUsecase === 'components' ? (
              <div
                className={classes.PageModule}
                onClick={() =>
                  props.activeService(selectedService + '/components')
                }
              >
                <img
                  alt=""
                  src={ComponentOpen}
                  className={classes.PageModuleImage}
                />
                <div className={classes.PageModuleFontActive}>COMPONENTS</div>
              </div>
            ) : (
              <div
                className={classes.PageModule}
                onClick={() =>
                  props.activeService(selectedService + '/components')
                }
              >
                <img
                  alt=""
                  src={ComponentClose}
                  className={classes.PageModuleImage}
                />
                <div className={classes.PageModuleFont}>COMPONENTS</div>
              </div>
            )}
          </React.Fragment>
        ) : null}
      </div>
      <div className={classes.SettingsContainer}>
        <img
          data-tip
          data-for="configIcon"
          className={classes.IconPointer}
          alt=""
          src={ConfigIcon}
          onClick={() => {
            props.activeService('cp');
          }}
        />
        <ReactTooltip
          className={classes.ToolTip}
          id="configIcon"
          place="right"
          effect="solid"
        >
          Config page
        </ReactTooltip>
        {props.isAdmin || props.isSiemens ? (
          <React.Fragment>
            <ReactTooltip
              className={classes.ToolTip}
              id="adminIcon"
              place="right"
              effect="solid"
            >
              Admin page
            </ReactTooltip>
            <img
              data-tip
              data-for="adminIcon"
              className={classes.IconPointer}
              alt=""
              src={AdminIcon}
              onClick={() => {
                props.activeService('ap');
              }}
            />
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default layer2;
