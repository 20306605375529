import React from 'react';
import * as Sentry from '@sentry/react';

import AppNavList from './AppNavList/AppNavList';
import PortalNavList from './PortalNavList/PortalNavList';

import classes from './NavList.module.css';

const navList = (props) => {
  let x = 1;
  return (
    <nav className={classes.NavList}>
      <PortalNavList
        close={props.close}
        openPlantSelection={props.openPlantSelection}
        username={props.username}
        unameToPnameMapping={props.unameToPnameMapping}
        pname={props.pname}
        ppid={props.ppid}
        redirectToCepUrl={props.redirectToCepUrl}
        isMimic={props.isMimic}
        mimicedEmail={props.mimicedEmail}
      />
      <AppNavList
        currentService={props.currentService}
        activeService={props.activeService}
        isSiemens={props.isSiemens}
        isAdmin={props.isAdmin}
        ppid={props.ppid}
        contracts={props.contracts}
        shaUnderMaintenance={props.shaUnderMaintenance}
        hlUnderMaintenance={props.hlUnderMaintenance}
        saUnderMaintenance={props.saUnderMaintenance}
      />
      <div className={classes.ContactUsGap}></div>
      {props.isMimic ? (
        <button
          className={classes.ContactUs}
          onClick={() => {
            Sentry.captureException('icma-user-feedback ' + x++);
            console.error('icma-user-feedback', x++);
          }}
        >
          Contact Us
        </button>
      ) : null}
    </nav>
  );
};

export default navList;
