import React, { useMemo, useEffect } from 'react';
import sortDown from '../../../assets/images/sortDown.svg';
import sortUp from '../../../assets/images/sortUp.svg';
import LoadingSpinner from '../LoadingSpinner';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  usePagination
} from 'react-table';
import classes from './TableComponent.module.css';
import { Checkbox } from './Checkbox';
import { ColumnFilter } from './ColumnFilter';

const TableComponent = (props) => {
  const columns = useMemo(() => props.columns, [props.columns]);
  let numberOfColumns = props.columns.length;
  const data = useMemo(() => props.data, [props.data]);
  const checkBoxSelection = props.enableSelection;
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter
    }),
    []
  );
  const tableInstance = useTable(
    { columns, data, defaultColumn },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      if (checkBoxSelection) {
        numberOfColumns = numberOfColumns + 1;
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            className: `${classes['ps-3']} ${classes['pe-0']}`,
            headerClassName: `${classes['ps-3']} ${classes.TableHeader} ${classes['pe-0']}`,
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <Checkbox
                {...getToggleAllPageRowsSelectedProps()}
                onClick={(e) => checkboxHandler(e)}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                {...row.getToggleRowSelectedProps()}
                onClick={(e) => checkboxHandler(e)}
              />
            )
          },
          ...columns
        ]);
      }
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    selectedFlatRows,
    page,
    rows,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize
  } = tableInstance;
  useEffect(() => {
    setGlobalFilter(props.globalFilter);
  }, [props.globalFilter]);
  useEffect(() => {
    if (props.minified) {
      setPageSize(5);
    }
    setPageSize(5);
  }, [props.minified]);

  const selectedRows = selectedFlatRows.map((row) => row.original);
  const stringifySelectRow = JSON.stringify(selectedRows);
  useEffect(() => {
    props.onselectedRows(selectedRows);
  }, [stringifySelectRow]);

  const { pageIndex, pageSize } = state;

  const checkboxHandler = (e) => {
    e.stopPropagation();
  };

  const rowClickHandler = (row) => {
    props.onRowSelect(row.original);
  };

  return (
    <div
      className={` ${classes['mt-3']} ${classes['mb-1']} ${
        props.minified ? '' : `${classes.TableContainer}`
      }`}
    >
      <div
        className={`${classes['d-flex']} ${classes['flex-row']} ${classes['justify-content-center']}`}
      >
        <table
          className={`${classes['table']} ${classes['table-borderless']} ${classes['table-hover']} ${classes['mb-0']} ${classes.CustomTable}`}
          {...getTableProps()}
        >
          <thead className={`${classes.TableHead}`}>
            {headerGroups.map((headerGroup) => (
              <tr
                className={`${classes.TableHeader}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className={`${
                      column.headerClassName
                        ? column.headerClassName
                        : `${classes['ps-3']} ${classes['pe-0']}`
                    }`}
                    {...column.getHeaderProps()}
                  >
                    {props.sortable ? (
                      <div {...column.getSortByToggleProps()}>
                        {column.i18n
                          ? column.render('Header')
                          : column.render('Header')}
                        {props.sortable ? (
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  src={sortDown}
                                  alt="Bootstrap"
                                  width="32"
                                  height="32"
                                  className={`${classes.SortImage}`}
                                />
                              ) : (
                                <img
                                  src={sortUp}
                                  alt="Bootstrap"
                                  width="32"
                                  height="32"
                                  className={`${classes.SortImage}`}
                                />
                              )
                            ) : (
                              <img
                                src={sortUp}
                                alt="Bootstrap"
                                width="32"
                                height="32"
                                className={`${classes.SortImage} ${classes.OpacityZero}`}
                              />
                            )}
                          </span>
                        ) : null}
                      </div>
                    ) : column.i18n ? (
                      column.render('Header')
                    ) : (
                      column.render('Header')
                    )}

                    <div>
                      {props.enableColumnFilter
                        ? column.canFilter
                          ? column.render('Filter')
                          : null
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            {props.isLoading && (
              <tr>
                <th colSpan={numberOfColumns}>
                  <div>
                    <LoadingSpinner small={false} />
                  </div>
                </th>
              </tr>
            )}
          </thead>

          <tbody className={`${classes.TableBody}`} {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  data-testid="table-row"
                  onClick={() => rowClickHandler(row)}
                  className={classes.TableRow}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={
                          cell.column.className
                            ? cell.column.className
                            : `${classes['ps-3']} ${classes['pe-0']}`
                        }
                        data-testid={cell.column.cellDataTestId || null}
                        {...cell.getCellProps()}
                      >
                        <div
                          className={`${classes['d-flex']} ${classes['align-items-center']}`}
                        >
                          {cell.render('Cell') || ''}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {pageCount > 1 ? (
            <tfoot>
              <tr className={`${classes.PaginationTr}`}>
                <th
                  className={`${classes.PaginationTh}`}
                  colSpan={numberOfColumns}
                >
                  <div
                    className={`${classes['d-flex']} ${classes['flex-row-reverse']} ${classes.PaginationBar}`}
                  >
                    <div
                      className={`${classes['d-flex']} ${classes['flex-row']} ${classes['justify-content-center']} ${classes['p-0']}`}
                    >
                      <span className={`${classes['ms-2']} ${classes['pt-2']}`}>
                        {props.minified ? 'Rows' : 'Show rows'}
                      </span>
                      <select
                        value={pageSize}
                        className={`${classes['ms-2']} ${classes['form-control']} ${classes['form-control-sm']} ${classes['na-custom-select']} ${classes.PaginationSelect} ${classes.PaginationInputBorders}`}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        {props.minified
                          ? [5].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            ))
                          : [5, 10, 15].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            ))}
                      </select>
                      <span className={`${classes['ms-2']} ${classes['pt-2']}`}>
                        {props.minified ? 'Go' : 'Go to'}
                      </span>
                      <input
                        type="text"
                        className={`${classes['ms-3']} ${classes['form-control']} ${classes['form-control-sm']} ${classes.PaginationInput} ${classes.PaginationInputBorders}`}
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                          const pageNumber = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          gotoPage(pageNumber);
                        }}
                      />
                      <span
                        className={`${classes['ms-4']} ${classes['pt-2']} ${classes.CurrentPageSection}`}
                      >
                        {Number(pageIndex) * pageSize + 1} -{' '}
                        {(Number(pageIndex) + 1) * pageSize > rows.length
                          ? rows.length
                          : (Number(pageIndex) + 1) * pageSize}{' '}
                        of {rows.length}
                      </span>
                      <button
                        className={`${classes['me-0']} ${classes['ms-3']} ${
                          classes.PaginationButtons
                        } ${classes.PaginationInputBorders} ${
                          classes.LeftPaginationButton
                        } ${!canPreviousPage ? `${classes.Disable}` : ''}`}
                        onClick={() => {
                          previousPage();
                        }}
                        disabled={!canPreviousPage}
                      >
                        <span
                          className={`${classes['pb-0']} ${classes.DoubleArrowLeft}`}
                        ></span>
                      </button>
                      <button
                        className={`${classes['ms-0']} ${classes['me-5']} ${
                          classes.PaginationButtons
                        } ${classes.PaginationInputBorders} ${
                          classes.RightPaginationButton
                        } ${!canNextPage ? `${classes.Disable}` : ''}`}
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        <span
                          className={`${classes['pb-0']} ${classes.DoubleArrowRight}`}
                        ></span>
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
            </tfoot>
          ) : null}
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
