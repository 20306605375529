import React from 'react';
import ReactTooltip from 'react-tooltip';

import ConfigIcon from '../../../../assets/images/config.svg';
import NewAdminIcon from '../../../../assets/images/adminnew.png';

import classes from './Layer1.module.css';

const layer1 = (props) => {
  return (
    <div className={classes.Layer1}>
      <div className={classes.AppName}>{props.currentServiceName}</div>
      {props.currentServiceName === 'Available Services' ? (
        <div className={classes.Terms}>
          <div
            data-tip
            data-for="termsIcon"
            onClick={props.openTermsAndConditions}
            className={classes.TermsIcon}
          >
            §
          </div>
          <ReactTooltip
            className={classes.ToolTip}
            id="termsIcon"
            place="right"
            effect="solid"
          >
            Third-party software information
          </ReactTooltip>
          <img
            data-tip
            data-for="configIcon"
            className={classes.IconPointer}
            alt=""
            src={ConfigIcon}
            onClick={() => {
              props.activeService('cp');
            }}
          />
          <ReactTooltip
            className={classes.ToolTip}
            id="configIcon"
            place="right"
            effect="solid"
          >
            Configuration page
          </ReactTooltip>
          <React.Fragment>
            {props.isAdmin || props.isSiemens ? (
              <React.Fragment>
                <img
                  data-tip
                  data-for="adminIcon"
                  className={classes.IconPointer}
                  alt=""
                  src={NewAdminIcon}
                  onClick={() => {
                    props.activeService('ap');
                  }}
                />
                <ReactTooltip
                  className={classes.ToolTip}
                  id="adminIcon"
                  place="right"
                  effect="solid"
                >
                  Admin page
                </ReactTooltip>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        </div>
      ) : null}
    </div>
  );
};

export default layer1;
