import React, { useEffect, useState } from 'react';

import Backdrop from '../../Ui/Backdrop/Backdrop';
import classes from '../VersionModel/VersionModel.module.css';
import ReactMarkdown from 'react-markdown';
import Select from 'react-select';
import CloseButton from '../../../assets/images/cross.svg';
import LoadingComponent from '../../ServiceComponents/LandingPage/LoadingComponent';
import { cep } from '../../../services/cep';

const VersionModel = (props) => {
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedVersionDetails, setSelectedVersionDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let versionArray = [];
    props.version_info.versions.forEach((v) => {
      versionArray.push({ value: v, label: v });
    });
    let currentVersion = versionArray.find(
      (v) => v.value === props.version_info.latest_version
    );
    if (!selectedVersion) {
      setSelectedVersion(currentVersion);
      setVersions(versionArray);
      setSelectedVersionDetails(props.version_info.latest_details.description);
    }
  }, [
    selectedVersion,
    props.version_info.latest_details.description,
    props.version_info.latest_version,
    props.version_info.versions
  ]);

  const getVersionDetails = (version) => {
    setIsLoading(true);
    cep.footer(props.settings.ppid, version.value).then((res) => {
      setSelectedVersionDetails(res['description']);
      setIsLoading(false);
    });
  };
  const handelSelectChange = (selected) => {
    setSelectedVersion(selected);
    getVersionDetails(selected);
  };
  return (
    <React.Fragment>
      <div className={classes.VersionModel}>
        <div className={classes.InfoContainer}>
          <pre className={classes.Pre}>
            <div className="model">
              <div className={`${classes.customHeader}`}>
                <div className={`card-header col-9 ${classes.iptCustomHeader}`}>
                  <h5>What’s Changed in ICMA</h5>
                </div>
                <div className={`col-3 ${classes.iptCustomHeaderEnd}`}>
                  <span className={`col-9 ${classes.customSelect}`}>
                    <Select
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: '#2B1E61',
                          color: 'white',
                          overflow: 'unset',
                          textOverflow: 'clip'
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: 'white',
                          overflow: 'unset',
                          textOverflow: 'clip'
                        }),
                        option: (base, { isFocused, isSelected }) => {
                          return {
                            ...base,
                            fontSize: '12px',
                            backgroundColor: isSelected
                              ? '#641e8c'
                              : isFocused
                              ? '#9631cd'
                              : '#fff',
                            color: isFocused || isSelected ? '#fff' : '#000',
                            borderColor:
                              isFocused || isSelected ? '#9631cd' : '',
                            transition: 'none !important'
                          };
                        }
                      }}
                      maxMenuHeight={120}
                      className={`${classes.customSelect}`}
                      options={versions}
                      value={selectedVersion}
                      defaultValue={selectedVersion}
                      onChange={handelSelectChange}
                    ></Select>
                  </span>
                  <span
                    onClick={props.close}
                    className={`col-3 ${classes.cardActionButton}`}
                  >
                    <i
                      class="bi bi-x"
                      style={{ fontSize: ' 35px' }}
                      alt="close button"
                      src={CloseButton}
                      onClick={props.close}
                    />
                  </span>
                </div>
              </div>
              <div className={`card-body ${classes.versionBody}`}>
                {isLoading ? (
                  <LoadingComponent customLoader={`${classes.customLoader}`} />
                ) : (
                  <ReactMarkdown>{selectedVersionDetails}</ReactMarkdown>
                )}
              </div>
            </div>
          </pre>
        </div>
      </div>
      <Backdrop show={true} clicked={props.close} />
    </React.Fragment>
  );
};

export default VersionModel;
