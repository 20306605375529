import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import LoadingScreen from '../../../Ui/LoadingScreen/LoadingScreen';

import classes from './IAndCLifecycleServices.module.css';
import { pushToError } from '../../../../utils/common';
class IAndCLifecycleServices extends Component {
  state = {
    htmlApiLoading: true,
  };
  componentDidMount() {
    this.props.setCurrentRouteState('hl/services', 'I&C Lifecycle');
    $('.' + classes.IAndCLifecycleServices).load(
      '/html/angularContainer.html',
      () => {
        const url =
          'https://dls-dev.siemens.cloud/sp/sp.static/html/hl_services.html';
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${this.props.settings.token}` }
          })
          .then(response => {
            $('#angularContainer').load(window.makeTextFile(response.data));
            this.setState({
              htmlApiLoading: false
            });
          })
          .catch((err) => {
            console.error(err);
            pushToError(this.props,this.state.pname,err,url);
          });
      }
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.state.htmlApiLoading ? <LoadingScreen /> : null}
        <div className={classes.IAndCLifecycleServices}>Lifecycle Services</div>
      </React.Fragment>
    );
  }
}

export default IAndCLifecycleServices;
