import React from 'react';

import Backdrop from '../Ui/Backdrop/Backdrop';
import NavList from './NavList/NavList';
import classes from './SideDrawer.module.css';

const sideDrawer = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Close];

  if (props.show) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }

  return (
    <React.Fragment>
      <div className={attachedClasses.join(' ')}>
        <NavList
          currentService={props.currentService}
          activeService={props.activeService}
          close={props.close}
          openPlantSelection={props.openPlantSelection}
          username={props.username}
          unameToPnameMapping={props.unameToPnameMapping}
          pname={props.pname}
          isSiemens={props.isSiemens}
          isAdmin={props.isAdmin}
          contracts={props.contracts}
          ppid={props.ppid}
          redirectToCepUrl={props.redirectToCepUrl}
          shaUnderMaintenance={props.shaUnderMaintenance.isUnderMnt}
          hlUnderMaintenance={props.hlUnderMaintenance.isUnderMnt}
          saUnderMaintenance={props.saUnderMaintenance.isUnderMnt}
          isMimic={props.isMimic}
          mimicedEmail={props.mimicedEmail}
        />
      </div>
      <Backdrop show={props.open} clicked={props.close} />
    </React.Fragment>
  );
};

export default sideDrawer;
