import React from "react";

import classes from "./Backdrop.module.css";

const backdrop = (props) => {
  let className = classes.Backdrop;

  if (props.customSelect === "child") {
    className = classes.SelectBackdropChild;
  } else if (props.customSelect === "parent") {
    className = classes.SelectBackdropParent;
  }
  return props.show ? (
    <div className={className} onClick={props.clicked}></div>
  ) : null;
};

export default backdrop;
