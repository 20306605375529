import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import $, { data } from 'jquery';
import TableRow from './TableRow';
import TableComponent from '../../Ui/TableComponent/TableComponent';
// import { GlobalFilter } from '../Ui/TableComponent/GlobalFilter';
import LoadingScreen from '../../Ui/LoadingScreen/LoadingScreen';
import Popup from './Popup';
import classes from './T3000Reports.module.css';
import { pushToError } from '../../../utils/common';
import { cep } from '../../../services/cep';
import { tr } from '../../../services/tr';

const regionMapping = require('../../../config/region_mapping.json');

function GetSortOrderDesc(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return -1;
    } else if (a[prop] < b[prop]) {
      return 1;
    }
    return 0;
  };
}

function processViewerResponse(props, response) {
  let oldJSON = response.data.results;
  let newJSON = oldJSON.sort(GetSortOrderDesc('createdOn'));
  let noDataFound, noDataLabel;
  if (oldJSON.length !== 0) {
    noDataFound = false;
  } else {
    noDataFound = true;
    if (props.settings.language === 'en') {
      noDataLabel = 'No Omnivise-T3000 reports were uploaded since seven days';
    } else if (props.settings.language === 'de') {
      noDataLabel =
        'Es wurden in den letzten sieben Tagen keine Omnivise-T3000 geladen';
    }
  }
  return { noDataFound, noDataLabel };
}

function getPlantDetails(props, response) {
  const ppidToPnameMapping =
    response?.data?.globalSettings['ppidToPnameMapping'];
  const ppidToUnitsMapping =
    response?.data?.globalSettings['ppidToUnitsMapping'];
  let plantName, unitName;
  if (props.ppid.includes('u')) {
    let baseppid = props.ppid.substring(0, props.ppid.indexOf('u'));
    plantName = ppidToPnameMapping[baseppid];
    unitName = ppidToPnameMapping[props.ppid];
  } else {
    plantName = ppidToPnameMapping[props.ppid];
  }
  const { ibid } = response?.data?.globalSettings;
  return {
    plantName,
    unitName,
    ibid
  };
}

const cellFormatter = ({ value, row }) => {
  let customClass;
  // check if the row ppid or unit is same as current props ppid and row aname is same as current props aname
  // if (
  //   this.props.aname === row.original.aname &&
  //   (this.props.ppid === row.original.ppid ||
  //     this.props.ppid === row.original.unitId)
  // ) {
  //   customClass = classes.CustomHighlight;
  // } else {
  //   customClass = classes.DefaultCell;
  // }
  customClass = classes.DefaultCell;
  return <span className={`${customClass}`}>{value}</span>;
};
const tableOptions = [
  {
    Header: 'Name',
    accessor: 'name',
    className: `${classes['ps-3']} ${classes['pe-0']} ${classes['icma-tr-td']}`,
    headerClassName: `${classes['ps-3']} ${classes['pe-0']} ${classes['icma-tr-th']}`,
    i18n: false,
    Cell: cellFormatter
  },
  {
    Header: 'Report Of',
    accessor: 'lastModifiedDate',
    className: `${classes['ps-3']} ${classes['pe-0']} ${classes['icma-tr-td']}`,
    headerClassName: `${classes['ps-3']} ${classes['pe-0']} ${classes['icma-tr-th']}`,
    i18n: false,
    Cell: cellFormatter
  },
  {
    Header: 'Received On',
    accessor: 'createdOn',
    className: `${classes['ps-3']} ${classes['pe-0']} ${classes['icma-tr-td']}`,
    headerClassName: `${classes['ps-3']} ${classes['pe-0']} ${classes['icma-tr-th']}`,
    i18n: false,
    Cell: cellFormatter
  }
];

const T3000Reports = (props) => {
  const [service, setService] = useState({
    name: 'Omnivise T3000 Reports'
  });
  const [results, setResults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  // const [fileName, setFileName] = useState(null);
  const [fileNameList, setFileNameList] = useState([]);
  const [methodName, setMethodName] = useState(null);
  const [keyPath, setKeyPath] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [showButtonLoader,setShowButtonLoader] = useState(false)
  const [plantName, setPlantName] = useState(null);
  const [unitName, setUnitName] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [noDataLabel, setNoDataLabel] = useState(null);
  const [regionName, setRegionName] = useState('Germany');
  const [serverName, setServerName] = useState(props.settings.server);
  const [rows, setRows] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const fileNames = [];

  useEffect(() => {
    const email = props.email;
    props.setCurrentRouteState('tr', 'Omnivise T3000 Reports');
    let lpMapping = props.lpMapping;
    // for rpe we need to use regional values in DEV
    if (
      window.location.hostname.includes('dev') ||
      window.location.hostname.includes('local')
    ) {
      const { plantName, unitName, ibid } = getPlantDetails(props, lpMapping);
      // now to fetch the regional details for the plant using the ppid
      cep.mapping(props.ppid, 'fetchWithPPID', ibid).then((response) => {
        // getting the aws region eg.eu-central-1
        const newPlantRegion = response['PlantRegion'];
        // getting the region name eg. germany
        const newRegionName = regionMapping[newPlantRegion]['name'];
        // getting the server name eg. icma-dev or icma-dev-us
        const newServerName = regionMapping[newPlantRegion]['server'];
        setRegionName(newRegionName);
        setServerName(newServerName);
        const viewerUrl = `${newServerName}tr/tr.viewer/${props.ppid}`;
        axios
          .get(viewerUrl, {
            headers: {
              Authorization: `Bearer ${props.settings.token}`
            }
          })
          .then((viewerResponse) => {
            let { noDataFound, noDataLabel } = processViewerResponse(
              props,
              viewerResponse
            );
            console.log(viewerResponse.data.results);
            setPlantName(plantName);
            setUnitName(unitName);
            setResults(viewerResponse.data.results);
            setShowLoader(false);
            setStartDate(viewerResponse.data.startDate);
            setEndDate(viewerResponse.data.endDate);
            setNoDataLabel(noDataLabel);
            setNoDataFound(noDataFound);
          })
          .catch((error) => {
            setShowLoader(false);
            console.log(error);
          });
      });
    } else {
      const { plantName, unitName } = getPlantDetails(props, lpMapping);

      // for qa and prod, currrently no rpe
      tr.viewer(props.ppid)
        .then((viewerResponse) => {
          let { noDataFound, noDataLabel } = processViewerResponse(
            props,
            viewerResponse
          );
          setPlantName(plantName);
          setUnitName(unitName);
          setResults(viewerResponse.data.results);
          setShowLoader(false);
          setStartDate(viewerResponse.data.startDate);
          setEndDate(viewerResponse.data.endDate);
          setNoDataLabel(noDataLabel);
          setNoDataFound(noDataFound);
        })
        .catch((error) => {
          setShowLoader(false);
          console.log(error);
        });
    }
  }, []);

  // Callback function to handle selected rows
  function handleSelectedRows(selectedRows) {
    // Perform actions with selectedRow
    let key = '';
    if (selectedRows.length > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    selectedRows.map((item, index) => {
      fileNames.push(item?.name);
      if (key === '') key = item?.key;
    });
    setFileNameList(fileNames);
    setKeyPath(key);
  }
  function onRowSelectHandler(selectedRow) {
    // don't allow single row popup once 2 rows are check marked
    if (fileNameList.length <= 1 && !showButton ) {
      //toggle popup on click of row
      setFileNameList([selectedRow?.name]);
      setKeyPath(selectedRow?.key);
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  // function downloadFileGet(fileName) {
  //   console.log(fileName);
  //   let url = `${serverName}tr/tr.download/${props.ppid}/${keyPath}`;
  //   setShowLoader(true);
  //   axios
  //     .get(url, {
  //       headers: { Authorization: `Bearer ${props.settings.token}` }
  //     })
  //     .then((response) => {
  //       if (response.data.progress >= 100 && response.data.url !== null) {
  //         setShowLoader(false);
  //         setDownloadUrl(response.data.url);
  //         setShowPopup(false);
  //       } else {
  //         setTimeout(() => {
  //           downloadFileGet(fileName);
  //         }, 3000);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(`error from api call ${error}`);
  //     });
  // }

  //  method for multi file download
  function downloadFilePost(filesname) {
    let methodName = '';
    let selectedFile = [];

    if (filesname.length > 1) {
      methodName = 'MultiSelect';
      selectedFile = filesname;
    }

    let parameter = {
      fileNameList: selectedFile,
      method: methodName
    };

    let url = `${serverName}tr/tr.download/${props.ppid}/${keyPath}`;
    setShowButtonLoader(true);
    axios
      .post(url, parameter, {
        headers: { Authorization: `Bearer ${props.settings.token}` }
      })
      .then((response) => {
        if (response.data.progress == 100 && response.data.url !== null) {
          setDownloadUrl(response.data.url);
          setShowButtonLoader(false);
          setShowLoader(false);
          setShowPopup(false);
        } else {
          setTimeout(() => {
            downloadFilePost(filesname);
          }, 3000);
        }
      })
      .catch(function (error) {
        console.log(`error from api call ${error}`);
      });
    setDownloadUrl(null);
  }

  return (
    <React.Fragment>
      <div className={`${classes['icma-tr']}`}>
        <div className={`${classes['icma-tr-header']}`}>
          <div>{service.name}</div>
          <span>
            {startDate} - {endDate}
          </span>
          <span>Data location: {regionName}</span>
          {showButton && (
            <button
              className={`${classes['dls-rp-downloadButton']}`}
              onClick={() => togglePopup()}
            >
              Download
            </button>
          )}
        </div>
        <div className={`${classes['icma-tr-tablediv']}`}>
          {noDataFound ? (
            <div className={`${classes['icma-tr-noDataBanner']}`}>
              <div>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFrSURBVEhLxZTPSsNAEMZXnymbQk+6CXooLVJREIuexJtHrzn6At58Cy9ePPgoKngStXSz1FLNOLPuSpNs/jUBP/hgkhm+X7KbLGsiYGwDIrZpLruVDPlhLPhbLPxpHPgn5nY3ksKbSMG/MBjIMvATJfxz026nbHinkKJw61aQqnDrtSCucDXchsXNtTbVq71GkKInV0cjsKI6268FKVsWNd4x8QjA2jVTCqlaczUSJh4BWLtmyE6IFP5B5YaGPRMPunbOGBMkDvipiScAf3ENZg3Jt7arlzd//ztW8A2e3ENpJ7HUdvUcfqWzSwPwYoCQRWYg52T6oe3qrZqWm5Zdh1vNQm9cBaHNLdtg8m+4NzGxaZVCdvuwvLuF5cM9/mhb+T66NNyqCKKO98w3BDC/OEv1yLXCrYog8+gSPq+i3GfaKNyqzp6Q1wq3qoK0CrcqgnQSbpWFdBpuhcEDGfBHBD3Pgt6+uf3fYuwHjmTot/MxIpMAAAAASUVORK5CYII=" />
              </div>
              <div className={`${classes['icma-tr-noDataBanner-text']}`}>
                {noDataLabel}
              </div>
            </div>
          ) : (
            <TableComponent
              data={results}
              sortable={true}
              enableColumnFilter={false}
              enableSelection={true}
              enableSelectAll={false}
              globalFilter={false}
              onRowSelect={onRowSelectHandler}
              columns={tableOptions}
              isLoading={showLoader}
              onselectedRows={(selectedRow) => handleSelectedRows(selectedRow)} // Pass the callback function
            />
          )}

          <iframe
            className={`${classes['dls-tr-downloadFrame']}`}
            src={downloadUrl}
          ></iframe>
        </div>
        {showPopup ? (
          <Popup
            text="Omnivise T3000"
            closePopup={togglePopup.bind(this)}
            fileNameList={fileNameList}
            checkDownload={(fileNameValue) => downloadFilePost(fileNameValue)}
            plantName={plantName}
            unitName={unitName}
            isLoading={showButtonLoader}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default T3000Reports;
