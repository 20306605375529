import React from 'react';
import { withRouter } from 'react-router-dom';

import classes from './ErrorRoute.module.css';
// import { useParams } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
import { noParams } from '../../../utils/common';
const cleanUrl = () => {
  let onlyRoute = noParams.split('/').slice(3).join('/');
  // remove accesstoken from the url
  if (window.location.href.includes('#access_token')) {
    noParams = window.location.href.split('#')[0];
    onlyRoute = noParams.split('/').slice(3).join('/');
  }
  return onlyRoute;
};
const errorRoute = (props) => {
  // const params = useParams();
  const search = props.location.search;
  const plantName =
    props.plantName || new URLSearchParams(search).get('plantName');
  const timeStamp =
    props.timeStamp || new URLSearchParams(search).get('timeStamp');
  const route = props.route || new URLSearchParams(search).get('route');
  const error = props.error || new URLSearchParams(search).get('error');
  const url = props.url || new URLSearchParams(search).get('url');
  window.history.replaceState({}, document.title, '/' + cleanUrl());
  return (
    <div className={classes.ErrorRoute}>
      <h2
        className={`${classes['d-flex']} ${classes['justify-content-center']} ${classes['align-items-center']} ${classes['mb-2']} ${classes['error-badge']} ${classes['text-align']}`}
      >
        Oops ! something went wrong
      </h2>
      <div
        className={`${classes['d-flex']} ${classes['justify-content-start']} ${classes['align-items-center']} ${classes['mb-2']} ${classes['error-badge']} ${classes['text-align']}`}
      >
        <div>
          <div className={`${classes['ml-2']}`}>
            <strong>Details : </strong>
            {error}
          </div>
          <div className={`${classes['ml-2']}`}>
            <strong>On route : </strong> {route}
          </div>
          <div className={`${classes['ml-2']}`}>
            <strong>Failed URL : </strong> {url}
          </div>
          <div className={`${classes['ml-2']}`}>
            <strong>Plant name : </strong> {plantName}
          </div>
          <div className={`${classes['ml-2']}`}>
            <strong>Time Stamp : </strong> {timeStamp}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(errorRoute);
