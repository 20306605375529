import axiosv2 from '../index';

// tr api resource of icma
const trApiResource = 'tr';
// Axios api for getting the userfleet info from snap
export const tr = {
  viewer: (ppid) => {
    return axiosv2
      .get(`${trApiResource}/${trApiResource}.viewer/${ppid}`)
      .then((response) => {
        return response;
      });
  }
};
