import React, { Component } from 'react';

class HlEmailConfig extends Component {
  state = {
    htmlApiLoading: true,
    showAlert: false
  };
  componentDidMount() {}

  componentDidUpdate(prevProps) {}
  render() {
    return (
      <React.Fragment>
        <h2> New arch components...🔥</h2>
      </React.Fragment>
    );
  }
}

export default HlEmailConfig;
