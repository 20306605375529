import React from 'react';

import { cleanUrl } from '../../../../utils/common';

import CloseButton from '../../../../assets/images/close.svg';
import BackArrow from '../../../../assets/images/back-arrow.svg';
import UserPortrait from '../../../../assets/images/user.svg';
import MimicUserPortrait from '../../../../assets/images/user-mimic.svg';
import PlantsSelect from '../../../../assets/images/plants.svg';
import classes from './PortalNavList.module.css';

const portalNavList = (props) => {
  let plantName;
  if (props.unameToPnameMapping[props.pname]) {
    plantName = props.unameToPnameMapping[props.pname];
  } else {
    plantName = props.pname;
  }

  const redirectToCep = () => {
    // removing query parameters from the url
    window.history.replaceState({}, document.title, '/' + cleanUrl());
    window.location.replace(
      `${props.redirectToCepUrl}/CC/s/redirect-to-edo?ppid=${props.ppid}`
    );
  };

  /*
  To handle both email variant
    normal user -> yash.sood@siemens.com -> sood 
    functional user -> icma.mimic4sales.eu@siemens-energy.com -> ICMA_EU_SalesSupport
    test user case -> plantuser93@gmail.com and plantamin48@gmail.com
    plantuser93@gmail.com shown as -> TEST USER
    firsnameonly@somecompany.de shown as -> FIRSNAMEONLY
    firsname.lastname@somecompany.de shown as -> LASTNAME
    service4plants.energy@siemens-energy.com shown as -> ENERGY
    icma.mimic4sales.eu@siemens-energy.com shown as -> ICMA_EU_SalesSupport
    icma.mimic4sales.rectpi@siemens-energy.com shown as -> ICMA_RECTPI_SalesSupport
    icma.mimic4sales.reccyber@siemens-energy.com shown as -> ICMA_RECCYBER_SalesSupport
  */
  const mimicLastNameProcessor = (email) => {
    if (props.isMimic) {
      if (email.includes('mimic4sales')) {
        let regionValue = email.split('.')[2]?.split('@')[0]?.toUpperCase();
        return `ICMA_${regionValue}_SalesSupport`;
      }
      if (email.includes('plantuser93') || email.includes('plantamin')) {
        return 'TEST USER';
      } else {
        if (email.split('@')[0].includes('.')) {
          return email.split('@')[0]?.split('.')[1]?.toUpperCase();
        } else {
          return email.split('@')[0]?.split('.')[0]?.toUpperCase();
        }
      }
    }
    return 'DEFAULT';
  };
  // generate lastname for the mimiced user
  let mimicedLastName = mimicLastNameProcessor(props.mimicedEmail);

  return (
    <div className={classes.PortalNavList}>
      <ul>
        <li className={classes.List} onClick={props.close}>
          <img className={classes.Test} alt="" src={CloseButton}></img>
          Menu
        </li>
        {!props.isMimic ? (
          <li className={classes.List} onClick={redirectToCep}>
            <img className={classes.Test} alt="" src={BackArrow}></img>
            Customer Energy Portal
          </li>
        ) : null}
        <li className={classes.List}>
          <img className={classes.Test} alt="" src={UserPortrait}></img>
          {props.username}
        </li>
        {props.isMimic ? (
          <li
            className={classes.List}
            title={`Mimiced email: ${props.mimicedEmail}`}
          >
            <span className={classes.Mimicbadge}>mimiced user</span>
            <img className={classes.Test} alt="" src={MimicUserPortrait}></img>
            <span className={classes.Mimictext}>{`${mimicedLastName}`}</span>
          </li>
        ) : null}
        <li className={classes.List} onClick={props.openPlantSelection}>
          <img className={classes.Test} alt="" src={PlantsSelect}></img>
          {plantName}
        </li>
        <li className={classes.List}></li>
      </ul>
    </div>
  );
};

export default portalNavList;
