import React from 'react';

import PrimaryNavbar from './PrimaryNavbar/PrimaryNavbar';
import SecondaryNavbar from './SecondaryNavbar/SecondaryNavbar';
import Notification from './Notification/Notification';
import classes from './Navbar.module.css';

const navbar = (props) => {
  let visibleNotification = props.notifications.map((eachNoti) => {
    return (
      <>
        <Notification
          bannertext={eachNoti.bannertext}
          severity={eachNoti.severity}
          key={eachNoti.bannertext}
        />
      </>
    );
  });
  return (
    <div className={classes.Navbar}>
      <PrimaryNavbar
        aname={props.aname}
        pname={props.pname}
        toggle={props.toggleSideDrawer}
        openPlantSelection={props.openPlantSelection}
        ppidToUnitsMapping={props.ppidToUnitsMapping}
        ppid={props.ppid}
        parentID={props.parentID}
        ppidToPnameMapping={props.ppidToPnameMapping}
        changePpid={props.changePpid}
        key={'PrimaryNavbar'}
      />
      {visibleNotification}
      <SecondaryNavbar
        isAdmin={props.isAdmin}
        isSiemens={props.isSiemens}
        refreshMock={props.refreshMock}
        currentServiceName={props.currentServiceName}
        currentService={props.currentService}
        activeService={props.activeService}
        openTermsAndConditions={props.openTermsAndConditions}
        contracts={props.contracts}
        key={'SecondaryNavbar'}
      />
    </div>
  );
};

export default navbar;
