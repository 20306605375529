import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import LoadingScreen from '../../../Ui/LoadingScreen/LoadingScreen';

import classes from './IAndCSecurityAdvisorCIv4.module.css';
import { pushToError } from '../../../../utils/common';
class IAndCSecurityAdvisorCIv4 extends Component {
  state = {
    htmlApiLoading: true
  };
  componentDidMount() {
    // sessionStorage.setItem('isShowCiv4', false);
    this.props.setCurrentRouteState('sa/advisor_civ4', 'I&C Security');
    if (!this.props.userfleetApiLoading) {
      $('.' + classes.IAndCSecurityAdvisorCIv4).load(
        '/html/angularContainer.html',
        () => {
          const url = `${this.props.settings.server}sa/sa.static/html/sa_recommendation.html`;
          axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${this.props.settings.token}`
              }
            })
            .then((response) => {
              $('#angularContainer').load(window.makeTextFile(response.data));
              this.setState({
                htmlApiLoading: false
              });
            })
            .catch((err) => {
              console.error(err);
              pushToError(this.props, this.state.pname, err, url);
            });
        }
      );
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userfleetApiLoading !== this.props.userfleetApiLoading) {
      this.setState({
        htmlApiLoading: true
      });
      if (this.props.isSiemens) {
        $('.' + classes.IAndCSecurityAdvisorCIv4).load(
          '/html/angularContainer.html',
          () => {
            const url = `${this.props.settings.server}sa/sa.static/html/sa_recommendation.html`;
            axios
              .get(url, {
                headers: {
                  Authorization: `Bearer ${this.props.settings.token}`
                }
              })
              .then((response) => {
                $('#angularContainer').load(window.makeTextFile(response.data));
                this.setState({
                  htmlApiLoading: false
                });
              })
              .catch((err) => {
                console.error(err);
                pushToError(this.props, this.state.pname, err, url);
              });
          }
        );
      } else {
        this.props.history.push('/sa/advisor');
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.htmlApiLoading || this.props.userfleetApiLoading ? (
          <LoadingScreen />
        ) : null}
        <div className={classes.IAndCSecurityAdvisorCIv4}></div>
      </React.Fragment>
    );
  }
}

export default IAndCSecurityAdvisorCIv4;
