import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import LoadingScreen from '../../Ui/LoadingScreen/LoadingScreen';

import classes from './DataShopping.module.css';
import { pushToError } from '../../../utils/common';
class DataShopping extends Component {
  state = {
    htmlApiLoading: true,
  };
  componentDidMount() {
    this.props.setCurrentRouteState('ds', 'Data Shopping');
    if (!this.props.userfleetApiLoading) {
      $('.' + classes.DataShopping).load('/html/angularContainer.html', () => {
        const url = `${this.props.settings.server}ds/ds.static/html/data-shopping.html`;
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${this.props.settings.token}` },
          })
          .then(responseData => {
            $('#angularContainer').load(window.makeTextFile(responseData.data));
            this.setState({
              htmlApiLoading: false
            });
          })
          .catch(error => {
            console.error(error);
            pushToError(this.props,this.state.pname,error,url);
          });
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userfleetApiLoading !== this.props.userfleetApiLoading) {
      this.setState({
        htmlApiLoading: true
      });
      $('.' + classes.DataShopping).load('/html/angularContainer.html', () => {
        const url = `${this.props.settings.server}ds/ds.static/html/data-shopping.html`;
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${this.props.settings.token}` },
          })
          .then(responseDs => {
            $('#angularContainer').load(window.makeTextFile(responseDs.data));
            this.setState({
              htmlApiLoading: false
            });
          })
          .catch(errDs => {
            console.error(errDs);
            pushToError(this.props,this.state.pname,errDs,url);
          });
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.htmlApiLoading || this.props.userfleetApiLoading ? (
          <LoadingScreen />
        ) : null}
        <div className={classes.DataShopping}></div>
      </React.Fragment>
    );
  }
}

export default DataShopping;
