import axiosv2 from '../index';

// Axios api for getting the userfleet info from snap
export const maintenance = {
  shMaintenance: (ppid, pname) => {
    const shaBody = { language: 'en', plantName: pname };
    return axiosv2
      .post(
        `sha/sh.recomreqprocess/sh.isPlantUnderMaintenance/${ppid}`,
        shaBody,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(({ data }) => {
        return data;
      });
  },
  hlMaintenance(ppid, pname) {
    const hlBody = { language: 'en', plantName: pname };
    return axiosv2
      .post(`hl/hl.obsreqprocess/hl.isPlantUnderMaintenance/${ppid}`, hlBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  saMaintenance(ppid, pname) {
    const saBody = { language: 'en', plantName: pname };
    return axiosv2
      .post(`sa/sa.secreqprocess/sa.isPlantUnderMaintenance/${ppid}`, saBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        return data;
      });
  }
};
