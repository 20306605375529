import React from "react";

const LoadingSpinner = (props) => {
  let spinner;
  if (props.small) {
    spinner = (
      <div
        className="ms-2 spinner-border spinner-border-sm text-primary"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  } else {
    spinner = (
      <div className={`d-flex justify-content-center`}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return <React.Fragment>{spinner}</React.Fragment>;
};

export default LoadingSpinner;
