import React from 'react';
import ReactTooltip from 'react-tooltip';
import * as Sentry from '@sentry/react';
import ServicesOpen from '../../../../assets/images/services-open.svg';

import ConfigIcon from '../../../../assets/images/config.svg';
import AdminIcon from '../../../../assets/images/Admin.png';

import classes from './Layer2Standalone.module.css';

const layer2Standalone = (props) => {
  let navTitle = '';
  switch (props.currentService) {
    case 'ap':
      navTitle = 'GENERAL SETTINGS';
      break;
    case 'cp':
      navTitle = 'PLANT SETTINGS';
      break;
    case 'if':
      navTitle = 'SETTINGS';
      break;
    case 'tr':
      navTitle = 'DASHBOARD';
      break;
    case 'um':
      navTitle = 'UI MOCK';
      break;
    case 'ds':
      navTitle = 'DASHBOARD';
      break;
    default:
      navTitle = '';
  }
  return (
    <div className={classes.Layer2Standalone}>
      <div className={classes.PagesContainer}>
        <div className={classes.PageModule}>
          <img alt="" src={ServicesOpen} className={classes.PageModuleImage} />
          <div className={classes.PageModuleFontActive}>{navTitle}</div>
        </div>
      </div>
      <div className={classes.SettingsContainer}>
        {props.currentService === 'um' ? (
          <button
            className={classes.RefreshButton}
            onClick={() => {
              // Sentry.close(1000).then(function () {
              //   // perform something after close
              //   console.info(
              //     'refreshing.. disabling sentry tracing/logging...'
              //   );
              // });
              props.refreshMock();
            }}
          >
            Refresh
          </button>
        ) : null}
        <ReactTooltip
          className={classes.ToolTip}
          id="configIcon"
          place="right"
          effect="solid"
        >
          Config page
        </ReactTooltip>
        <img
          data-tip
          data-for="configIcon"
          className={classes.IconPointer}
          alt=""
          src={ConfigIcon}
          onClick={() => {
            props.activeService('cp');
          }}
        />
        {props.isAdmin || props.isSiemens ? (
          <React.Fragment>
            <ReactTooltip
              className={classes.ToolTip}
              id="adminIcon"
              place="right"
              effect="solid"
            >
              Admin page
            </ReactTooltip>
            <img
              data-tip
              data-for="adminIcon"
              className={classes.IconPointer}
              alt=""
              src={AdminIcon}
              onClick={() => {
                props.activeService('ap');
              }}
            />
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default layer2Standalone;
