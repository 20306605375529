// src/server.js
import { createServer } from 'miragejs';
import contractMockData from './data/contactsMock.json';
import icmaMappingMockData from './data/icmaMappingMockData.json';
import userfleet from './data/userfleet.json';
import notificationMockData from './data/notificationMockData.json';
import versionsLatestMock from './data/versionsLatestMock.json';
import serviceStructureMockData from './data/serviceStructureMock.json';
export function makeServer({ environment = 'test' } = {}) {
  createServer({
    routes() {
      this.urlPrefix = 'https://icma-dev.siemens-energy.cloud/';

      this.passthrough('http://localhost:3000/**');
      this.passthrough('http://localhost:5000/**');
      this.passthrough('http://localhost:3000/js/angular.min.js.map');
      this.passthrough('https://siemens-qa-ps-002.eu.auth0.com/**');
      this.passthrough('/ds/ds.static/**');
      this.passthrough('/aa/aa.static/**');
      this.passthrough('/sp/sp.static/**');
      this.passthrough('/sha/sh.static/**');
      this.passthrough('/aa/components/**');
      this.passthrough('/hl/hl.static/**');
      this.passthrough('/scdp/**');
      this.passthrough('/pc/pc.static/**');
      this.passthrough('/sa/sa.static/**');
      this.passthrough('/pi/pi.admin/**');
      this.passthrough('/tr/tr.static/**');
      this.passthrough('/tr/tr.static/**');
      this.passthrough('/pi/pi.config/pi.serviceconfigurations/**');
      // get ICMA Mapping mock
      this.get('/cep/cep.mapping/:ppid', (schema, request) => {
        return icmaMappingMockData;
      });

      // get UserFleetDetails mock
      this.get('/cep/cep.snap/:ppid', (schema, request) => {
        return userfleet;
      });

      // get notification mock
      this.get('/cep/cep.notify/:ppid', (schema, request) => {
        return notificationMockData;
      });
      // mock get version updated
      this.post('/cep/cep.user/:ppid', () => false);

      // get contact mock
      this.get('/pi/pi.landing/pi.mapping/:ppid', (schema, request) => {
        return contractMockData;
      });
      // get contact mock
      this.get(
        '/aa/aa.landing/optimization/:ppid/:language/:startdate/:enddate',
        (schema, request) => {
          return {
            optimization: -2,
            text: [
              'Systematic analysis of Omnivise T3000  Archive and engineering data that provides transparency and recommends solutions to reduce the amount of alerts, optimize archive load, manual operations and identify further improvements in DCS and process.',
              'No data in selected time range.'
            ],
            metadata: {
              anotherOptimizationValue: null,
              limitalert: 20,
              severityOfLimits: null
            },
            event: {
              resourcePath: '/aa.landing/optimization/{any+}',
              resource: 'aa.landing',
              ppid: '41096',
              language: 'en',
              startDate: '2023-06-05',
              endDate: '2023-06-06'
            },
            ppid: '41096'
          };
        }
      );
      this.get(
        '/sha/sh.landing/optimization/:ppid/:language/:startdate/:enddate',
        (schema, request) => {
          return {
            text: [
              'Evaluation of Omnivise T3000 component’s health status can reduce the risk of failure or malfunctions and thus help to increase a stable system operation. Potential weak points are identified early in advance before they turn into a problem which reduces the risk of unwanted outages.',
              '167 recommendations/findings for 26 components have been found.'
            ],
            optimization: 0.61,
            ppid: '41096'
          };
        }
      );
      this.get(
        '/tp/tp.landing/optimization/:ppid/:language/:startdate/:enddate',
        (schema, request) => {
          return {
            optimization: -2,
            text: [
              'Thermodynamic analysis of archive data gives recommendations for optimizations leading to more efficient and stable plant operation with reduced emissions.',
              'For the selected time period no use case could be evaluated.'
            ],
            ppid: '41096'
          };
        }
      );
      this.get(
        '/hl/hl.landing/optimization/:ppid/:language/:startdate/:enddate',
        (schema, request) => {
          return {
            ppid: '41096',
            optimization: 1,
            text: [
              'I&C Lifecycle provides important information about the current status of the components in the Product Lifecycle. The target is to identify measures which help to secure the availability of components. This service will be provided for SPPA-T2000 and Omnivise T3000 components and can be extended on request to other Siemens components and 3rd party components included in SPPA-T2000/Omnivise T3000.',
              '42.5 % of the components specified are already obsolete or will be obsolete in the near future.'
            ]
          };
        }
      );
      this.get(
        '/sa/sa.landing/optimization/:ppid/:language/:startdate/:enddate',
        (schema, request) => {
          return {
            text: [
              'Continues evaluation of the Omnivise T3000 security level, using robust and well-established analysis methods helps to keep the high availability of the plant’s DCS by detection and closing of security gaps as well as identification and mitigation of security events.',
              'It is recommended to install 22 security patches on 11 components.'
            ],
            optimization: 0.61,
            ppid: '41096'
          };
        }
      );
      // this.get('/pi/pi.landing/pi.mapping/:ppid', () => usecase);
      //Mock sh maintenance apis

      this.post(
        '/sha/sh.recomreqprocess/sh.isPlantUnderMaintenance/:ppid',
        (schema, request) => {
          return {
            reason: '',
            isUnderMnt: false
          };
        }
      );
      //Mock hl maintenance apis
      this.post(
        '/hl/hl.obsreqprocess/hl.isPlantUnderMaintenance/:ppid',
        (schema, request) => {
          return {
            reason: '',
            isUnderMnt: false
          };
        }
      );
      // Mock Sa Maintenance apis
      this.post(
        '/sa/sa.secreqprocess/sa.isPlantUnderMaintenance/:ppid',
        (schema, request) => {
          return {
            reason: '',
            isUnderMnt: false
          };
        }
      );

      // Mock Services Structure apis
      this.get(
        '/pi/pi.config/pi.servicestructures/:ppid',
        (schema, request) => {
          return serviceStructureMockData;
        }
      );

      this.get(
        '/pi/pi.config/pi.servicestructures/:ppid/**',
        (schema, request) => {
          return serviceStructureMockData;
        }
      );
      // mock get version api
      this.get('/cep/cep.footer/:ppid', (schema, request) => {
        if (request.queryParams.function === 'latest') {
          return versionsLatestMock;
        } else {
          return {
            version: 'v2.22.4',
            date: '10052023',
            description: 'Mocking Version data default'
          };
        }
      });
    }
  });
}
